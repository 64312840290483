import * as React from "react"
import {
    addPropertyControls,
    ControlType,
    EnumControlDescription,
    NumberControlDescription,
    RenderTarget,
    Color,
    withCSS,
} from "framer"
import {
    getVariantControls,
    fontSizeOptions,
    containerStyles,
    fontControls,
    useFontControls,
    usePadding,
    paddingControl,
} from "https://framer.com/m/framer/default-utils.js@^0.45.0"

/**
 * SELECT
 *
 * @framerIntrinsicWidth 140
 * @framerIntrinsicHeight 50
 *
 * @framerSupportedLayoutWidth any
 * @framerSupportedLayoutHeight any
 */
export const Select: React.ComponentType<any> = withCSS<any>(
    function Select(props) {
        const {
            disabled,
            focused,
            options = [],
            defaultTextColor,
            defaultIconColor,
            defaultBackgroundColor,
            defaultBorderColor,
            defaultBorderWidth,
            focusTextColor,
            focusIconColor,
            focusBackgroundColor,
            focusBorderColor,
            focusBorderWidth,
            disabledTextColor,
            disabledIconColor,
            disabledBackgroundColor,
            disabledBorderColor,
            disabledBorderWidth,
            disabledOpacity,
            radius,
            fontSize,
            fontFamily,
            fontWeight,
            value,
            variant,
            onFocus,
            onBlur,
            onChange,
            onSelectOption1,
            onSelectOption2,
            onSelectOption3,
            onSelectOption4,
            onSelectOption5,
            onSelectOption6,
            onSelectOption7,
            onSelectOption8,
            onSelectOption9,
            onSelectOption10,
            style,
            padding,
            ...rest
        } = props
        const [currentValue, setCurrentValue] = React.useState(value - 1)
        const isInPreview = RenderTarget.current() === RenderTarget.preview

        const handleSelectChange = React.useCallback(
            (event: React.ChangeEvent<HTMLSelectElement>) => {
                const index = parseInt(event.currentTarget.value, 10)
                const label = options[index]

                // This shouldn't happen!
                if (!label) {
                    console.error(
                        `Could not find option label at index ${index}`
                    )
                }

                setCurrentValue(index)

                if (onChange) {
                    onChange(index, label)
                }

                switch (index) {
                    case 0:
                        onSelectOption1()
                        break
                    case 1:
                        onSelectOption2()
                        break
                    case 2:
                        onSelectOption3()
                        break
                    case 3:
                        onSelectOption4()
                        break
                    case 4:
                        onSelectOption5()
                        break
                    case 5:
                        onSelectOption6()
                        break
                    case 6:
                        onSelectOption7()
                        break
                    case 7:
                        onSelectOption8()
                        break
                    case 8:
                        onSelectOption9()
                        break
                    case 9:
                        onSelectOption10()
                        break
                }
            },
            [
                options,
                onSelectOption1,
                onSelectOption2,
                onSelectOption3,
                onSelectOption4,
                onSelectOption5,
                onSelectOption6,
                onSelectOption7,
                onSelectOption8,
                onSelectOption9,
                onSelectOption10,
            ]
        )

        const handleFocus = React.useCallback(() => {
            if (onFocus) {
                onFocus()
            }
        }, [onFocus])

        const handleBlur = React.useCallback(() => {
            if (onBlur) {
                onBlur()
            }
        }, [onBlur])

        React.useEffect(() => setCurrentValue(value - 1), [value])

        const variants = {
            default: {
                backgroundColor: defaultBackgroundColor,
                caretColor: defaultTextColor,
                color: defaultTextColor,
                boxShadow: `inset 0 0 0 ${defaultBorderWidth}px ${defaultBorderColor}`,
            },
            disabled: {
                backgroundColor: disabledBackgroundColor,
                color: disabledTextColor,
                caretColor: disabledTextColor,
                boxShadow: `inset 0 0 0 ${disabledBorderWidth}px ${disabledBorderColor}`,
                opacity: disabledOpacity / 100,
            },
            focus: {
                backgroundColor: focusBackgroundColor,
                color: focusTextColor,
                caretColor: focusTextColor,
                boxShadow: `inset 0 0 0 ${focusBorderWidth}px ${focusBorderColor}`,
            },
        }

        const currentVariantStyle = disabled
            ? variants.disabled
            : variants[variant]

        const fontStyles = useFontControls(props)
        const paddingValue = usePadding(props)

        return (
            <select
                className="framer-default-select"
                disabled={variant === "disabled" || disabled}
                autoFocus={focused && isInPreview}
                style={{
                    ...(selectStyles as any),
                    ...fontStyles,
                    ...style,
                    "--framer-default-select-focus-background-color":
                        variants.focus.backgroundColor,
                    "--framer-default-select-focus-color": variants.focus.color,
                    "--framer-default-select-focus-box-shadow":
                        variants.focus.boxShadow,
                    "--framer-default-select-disabled-color":
                        variants.disabled.opacity,
                    padding: paddingValue,
                    borderRadius: radius,
                    fontSize,
                    backgroundImage: `url("data:image/svg+xml,${encodeURIComponent(
                        `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16">
                        <path d="M 0 9 L 4.5 4.5 L 0 0" transform="translate(5.75 3.75) rotate(90 2.25 4.5)" fill="transparent" stroke-width="2" stroke="${defaultIconColor}" stroke-linecap="round"></path>
                      </svg>`
                    )}")`,
                    ...currentVariantStyle,
                }}
                value={currentValue}
                onChange={handleSelectChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
            >
                {options.map((option, index) => (
                    <option key={`option-${index}`} value={index}>
                        {option}
                    </option>
                ))}
            </select>
        )
    },
    [
        ".framer-default-select:focus {background-color: var(--framer-default-select-focus-background-color);color: var(--framer-default-select-focus-color);box-shadow: var(--framer-default-select-focus-box-shadow);}",
        ".framer-default-select:disabled {opacity: var(--framer-default-select-disabled-color);cursor:default;}",
    ]
)

Select.defaultProps = {
    height: 50,
    width: 140,
    value: 1,
    disabled: false,
    focused: false,
    defaultTextColor: "#444444",
    defaultIconColor: "#999999",
    defaultBackgroundColor: "#EBEBEB",
    defaultBorderColor: "#09F",
    defaultBorderWidth: 0,
    focusTextColor: "#444444",
    focusIconColor: "#999999",
    focusBackgroundColor: "#f2f2f2",
    focusBorderColor: "#09F",
    focusBorderWidth: 1,
    disabledTextColor: "#444444",
    disabledIconColor: "#999999",
    disabledBackgroundColor: "#f2f2f2",
    disabledBorderColor: "#09F",
    disabledBorderWidth: 0,
    disabledOpacity: 60,
    radius: 8,
    padding: 10,
    paddingPerSide: true,
    paddingTop: 15,
    paddingRight: 50,
    paddingBottom: 15,
    paddingLeft: 15,
    fontWeight: 500,
    fontSize: 16,
    options: ["Option 1", "Option 2"],
    onChange: (index: number, label: string) => {},
    onFocus: () => {},
    onBlur: () => {},
    onSelectOption1: (index: number, label: string) => {},
    onSelectOption2: (index: number, label: string) => {},
    onSelectOption3: (index: number, label: string) => {},
    onSelectOption4: (index: number, label: string) => {},
    onSelectOption5: (index: number, label: string) => {},
    onSelectOption6: (index: number, label: string) => {},
    onSelectOption7: (index: number, label: string) => {},
    onSelectOption8: (index: number, label: string) => {},
    onSelectOption9: (index: number, label: string) => {},
    onSelectOption10: (index: number, label: string) => {},
}

const selectStyles: React.CSSProperties = {
    pointerEvents: "auto",
    WebkitAppearance: "none",
    appearance: "none",
    position: "relative",
    border: "none",
    margin: 0,
    cursor: "pointer",
    outline: 0,
    outlineWidth: 0,
    display: "inline-block",
    backgroundRepeat: "no-repeat",
    boxSizing: "border-box",
    backgroundPosition: "right 15px center",
    paddingLeft: 15,
    paddingRight: 15,
} as any

addPropertyControls(Select, {
    value: {
        type: ControlType.Number,
        title: "Default",
        min: 1,
        displayStepper: true,
        step: 1,
        defaultValue: Select.defaultProps.value,
    },
    disabled: {
        type: ControlType.Boolean,
        title: "Disabled",
        defaultValue: Select.defaultProps.disabled,
        enabledTitle: "Yes",
        disabledTitle: "No",
    },
    variant: {
        title: "Variant",
        type: ControlType.Enum,
        options: ["default", "focus", "disabled"],
        optionTitles: ["Default", "Focus", "Disabled"],
    },
    ...getVariantControls(Select, "default"),
    ...getVariantControls(Select, "focus"),
    ...getVariantControls(Select, "disabled", {
        disabledOpacity: {
            title: "Opacity",
            type: ControlType.Number,
            min: 0,
            max: 100,
            unit: "%",
            defaultValue: Select.defaultProps.disabledOpacity,
            hidden: (props) => props.variant !== "disabled",
        },
    }),
    ...paddingControl,
    radius: {
        title: "Radius",
        type: ControlType.FusedNumber,
        defaultValue: Select.defaultProps.radius,
        toggleKey: "isMixed",
        toggleTitles: ["Radius", "Radius per corner"],
        valueKeys: ["topLeft", "topRight", "bottomRight", "bottomLeft"],
        valueLabels: ["TL", "TR", "BR", "BL"],
        min: 0,
    },
    ...fontControls,
    fontSize: {
        ...(fontSizeOptions as NumberControlDescription),
        defaultValue: Select.defaultProps.fontSize,
    },
    fontWeight: {
        ...(fontControls.fontWeight as EnumControlDescription),
        defaultValue: Select.defaultProps.fontWeight,
    },
    focused: {
        type: ControlType.Boolean,
        title: "Focused",
        defaultValue: Select.defaultProps.focused,
        enabledTitle: "Yes",
        disabledTitle: "No",
    },
    options: {
        type: ControlType.Array,
        title: "Options",
        defaultValue: Select.defaultProps.options,
        propertyControl: {
            type: ControlType.String,
            placeholder: "Option…",
        },
    },
    onFocus: {
        type: ControlType.EventHandler,
    },
    onBlur: {
        type: ControlType.EventHandler,
    },
    onSelectOption1: {
        type: ControlType.EventHandler,
    },
    onSelectOption2: {
        type: ControlType.EventHandler,
    },
    onSelectOption3: {
        type: ControlType.EventHandler,
    },
    onSelectOption4: {
        type: ControlType.EventHandler,
    },
    onSelectOption5: {
        type: ControlType.EventHandler,
    },
    onSelectOption6: {
        type: ControlType.EventHandler,
    },
    onSelectOption7: {
        type: ControlType.EventHandler,
    },
    onSelectOption8: {
        type: ControlType.EventHandler,
    },
    onSelectOption9: {
        type: ControlType.EventHandler,
    },
    onSelectOption10: {
        type: ControlType.EventHandler,
    },
} as any)
